<template>
    <div>
        <h2 class="text-secondary">{{ theme.pageTitle }}</h2>
    </div>

    <TabNavigation :theme="theme" />
</template>

<script>
    import TabNavigation from "@/components/TabNavigation";

    export default {
        name: 'InterventiesThema',
        components: {
            TabNavigation,
        },
        data() {
            return {
                pageTitle: 'Interventies',
                theme: {}
            }
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
        mounted() {
            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Acties',
                    'path': '/interventies/'
                },
                {
                    'label': this.theme.pageTitle,
                },
            ];
            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        }
    }
</script>
