<template>
    <li class="nav-tabs__item" v-if="aspect.primaryIntervention && theme.slug">
        <router-link :to="{ name: 'interventie-thema-detail', params: { thema: theme.slug, aspect: aspect.slug, interventie: aspect.primaryIntervention.slug }}" class="block-link">
            {{ aspect.title }}
            <span><icon icon="chevron-right"/></span>
        </router-link>
    </li>
    <li v-else>
        <div class="block-link disabled">
            {{ aspect.title }}
            <span><icon icon="chevron-right"/></span>
        </div>
    </li>
</template>

<script>
// @ is an alias to /src
import Icon from "@/components/Icon";

export default {
    name: 'interventie',
    components: {Icon},
    props: {
        aspect: {type: Object, required: true},
        theme: {type: Object, required: true},
    }
}
</script>